<template>
 <div class="support nav-spcr">
            <h1 class="text-center text-title-1 mt-5 mb-4">Support</h1>

    <VueFaqAccordion
    :items="myItems"
    :activeColor="activeColor"
    class="mx-auto"
  />
  </div>
</template>


<script>
import VueFaqAccordion from 'vue-faq-accordion';

export default {
  name: 'support',
      components: {
    VueFaqAccordion
  },
    data () {
    return {
      myItems: [
          {
            title: "How do I download SYPHER?",
            value: "Tap the Download button in the menu and then tap the App Store icon. You need an iPhone or iPad to use SYPHER.",
          },
          {
            title: "Is there an Android version of SYPHER?",
            value: "The Android version is in the works. No timing yet.",
          },
          {
            title: "Can I use SYPHER without a premium music service account?",
            value: "Yes, you will be able to make posts in the community and participate in discussion. However, you will not be able to start your own live radio or join another radio. You will need a premium music streaming account with Apple Music or Spotify to listen within SYPHER.",
          },
          // {
          //   title: "Can Apple Music and Spotify users listen to each other's radios?",
          //   value: "Yes, the live radio has an algorithm that matches songs from different streaming platforms and allows all users to listen to the same music. Listeners get a notification for songs that cannot be matched (e.g. songs that do not exist on both platforms).",
          // },
          {
            title: "Is this illegaly sharing music?",
            value: "No. Music streamed through SYPHER is sourced from your premium music subscription via Apple Music or Spotify. When you join or start a live radio, your music stream is independent from everybody else's stream.",
          },
          {
            title: "How do artists get compensated on SYPHER?",
            value: "Since you can only listen to music in SYPHER with a premium music service as your legal access point, artists' compensation is provided in the same manner as it would be if you were listening through your premium music platform.",
          }
        ],
        activeColor: '#ed6a00',
        borderColor: ''


    }

  }
  
}
  

</script>
